@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;500;600;700;800;900&family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap');

.font-pop{
    font-family: 'Poppins', sans-serif;
}
.font-monta{
    font-family: 'Montserrat', sans-serif;
}
.font-rale{
    font-family: 'Raleway', sans-serif;
}

.w-fill-av{
    width: -webkit-fill-available;
}

.bg-colors{
    background: linear-gradient(90deg, #033587 0%, #4285F4 51.56%, #033587 100%);

}
.swiper-pagination-bullets{
    margin-left: 5rem;
}
.swiper-pagination-bullet{
    background-color: white !important;

}

.swiper-pagination-bullet-active {
    background-color: white !important;
}
body{
    cursor: url(../public/assets/cursor.png),auto;
}
.bgcc1{
background: linear-gradient(90deg, #4285F4 57.3%, #FBBD12 57.31%);
}


.bgcc2{
    background: linear-gradient(180deg, #4285F4 57.3%, #FBBD12 57.31%);
}


.bg-Grad1{
    background: linear-gradient(180deg, #4285F4 0%, #003EA6 100%) ;





}
.skewed-bottom {
    padding-bottom: 8em;
    background:
    linear-gradient(to bottom right, #003EA6 50%, transparent 50.5%) no-repeat bottom,
    /* bottom part */
    linear-gradient(0deg, #003EA6, #003EA6) no-repeat top;
    background-size: 100% 7em, 100% calc(100% - 7em);
  background-repeat: no-repeat;
  }



  .skewed-top-about {

    background:
    linear-gradient(to top left, #4285F4 50%, transparent 50.5%) no-repeat top,
    /* bottom part */
    linear-gradient(0deg, #003EA6, #4285F4) no-repeat bottom;
    background-size: 100% 9em, 100% calc(100% - 9em);
  background-repeat: no-repeat;
  }
  .skewed-top {

    background:
    linear-gradient(to top left, #4285F4 50%, transparent 50.5%) no-repeat top,
    /* bottom part */
    linear-gradient(0deg, #4285F4, #4285F4) no-repeat bottom;
    background-size: 100% 9em, 100% calc(100% - 9em);
  background-repeat: no-repeat;
  }
.borderSpecial{


    border-image: linear-gradient(#FFFF, #2350F0) 30;
    border-width: 0.5px;
    border-style: solid;
}
.swiper-scrollbar-drag{
    background-color: #FBBD12 !important;
}
.bg-multiply{
    opacity: 0.4;
}
.inner-shad{

box-shadow: inset 0px 0px 12px rgba(0, 0, 2, 0.5);

}
